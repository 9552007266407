"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hindsightMaterialSchema = void 0;
const materialTypes_1 = require("../types/materialTypes");
exports.hindsightMaterialSchema = {
    $id: 'https://buyplan.nike.com/hindsightMaterialSchema.json',
    $schema: 'http://json-schema.org/schema#',
    additionalProperties: false,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            format: 'uuid',
        },
        hindsightFileId: {
            type: 'string',
            minLength: 1,
            format: 'uuid',
        },
        materialCode: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        division: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        category: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        family: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        emeaFamily: {
            type: 'string',
            maxLength: 255,
        },
        model: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        description: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        colorDescription: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        style: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        yearType: {
            type: 'number',
            minimum: 0,
            maximum: 1,
        },
        age: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        gender: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        silhouette: {
            type: 'string',
            minLength: 0,
            maxLength: 255,
        },
        typeOfOrder: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        segment: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Segment),
        },
        launchFlag: {
            type: ['string', 'null'],
            minLength: 0,
            maxLength: 3,
        },
        consumer: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Consumer),
        },
        brand: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Brand),
        },
        franchise: {
            type: 'string',
            maxLength: 255,
        },
        merchClass: {
            type: 'string',
            maxLength: 255,
        },
        consumerUse: {
            type: 'string',
            maxLength: 255,
        },
        emeaDimension: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        emeaSilo: {
            type: 'string',
            maxLength: 255,
        },
        emeaSubFranchise: {
            type: 'string',
            maxLength: 255,
        },
        emeaCollectionName: {
            type: 'string',
            maxLength: 255,
        },
        silhouetteType: {
            type: 'string',
            maxLength: 255,
        },
        thirdParty: {
            type: 'string',
            maxLength: 255,
        },
        fieldsOfPlay: {
            type: 'string',
            maxLength: 255,
        },
        features: {
            type: 'string',
            maxLength: 255,
        },
        emeaFootballPlates: {
            type: 'string',
            maxLength: 255,
        },
        emeaBusinessModel: {
            type: 'string',
            maxLength: 255,
        },
    },
    errorMessage: {
        required: {
            id: 'ID is required',
            hindsightFileId: 'Hindsight file ID is required',
            materialCode: 'Material code is required',
            division: 'Division is required',
            category: 'Category is required',
            family: 'Family is required',
            model: 'Model is required',
            description: 'Description is required',
            colorDescription: 'Color description is required',
            style: 'Style is required',
            yearType: 'Year type is required',
            age: 'Age is required',
            gender: 'Gender is required',
            silhouette: 'Silhouette is required',
            typeOfOrder: 'Type of order is required',
            segment: 'Segment is required',
            consumer: 'Consumer is required',
            brand: 'Brand is required',
            emeaDimension: 'EMEA Dimension is required',
        },
        properties: {
            id: 'ID must be a UUID',
            hindsightFileId: 'Hindsight file ID must be a UUID',
            materialCode: 'Material code must be a string with a minimum length of 1 and a maximum length of 45',
            division: 'Division must be a string with a minimum length of 1 and a maximum length of 255',
            category: 'Category must be a string with a minimum length of 1 and a maximum length of 255',
            family: 'Family must be a string with a minimum length of 1 and a maximum length of 255',
            emeaFamily: 'EMEA family must be a string with a minimum length of 1 and a maximum length of 255',
            model: 'Model must be a string with a minimum length of 1 and a maximum length of 255',
            description: 'Description must be a string with a minimum length of 1 and a maximum length of 255',
            colorDescription: 'Color description must be a string with a minimum length of 1 and a maximum length of 255',
            style: 'Style must be a string with a minimum length of 1 and a maximum length of 45',
            yearType: 'Year type must be 0 or 1',
            age: 'Age must be a string with a minimum length of 1 and a maximum length of 255',
            gender: 'Gender must be a string with a minimum length of 1 and a maximum length of 255',
            sihouette: 'Silhouette must be a string with a minimum length of 0 and a maximum length of 255',
            typeOfOrder: 'Type of order must be a string with a minimum length of 1 and a maximum length of 255',
            segment: `Segment must be one of the following: ${Object.keys(materialTypes_1.Segment).join(', ')}`,
            launchFlag: 'Launch flag must be a string with a minimum length of 0 and a maximum length of 3',
            consumer: `Consumer must be one of the following: ${Object.keys(materialTypes_1.Consumer).join(', ')}`,
            brand: `Brand must be one of the following: ${Object.keys(materialTypes_1.Brand).join(', ')}`,
            franchise: 'Franchise must be a string with a minimum length of 1 and a maximum length of 255',
            merchClass: 'Merch class must be a string with a minimum length of 1 and a maximum length of 255',
            consumerUse: 'Consumer use must be a string with a minimum length of 1 and a maximum length of 255',
            emeaDimension: 'EMEA dimension must be a string with a minimum length of 1 and a maximum length of 255',
            emeaSilo: 'EMEA silo must be a string with a minimum length of 1 and a maximum length of 255',
            emeaSubFranchise: 'EMEA sub franchise must be a string with a minimum length of 1 and a maximum length of 255',
            emeaCollectionName: 'EMEA collection name must be a string with a minimum length of 1 and a maximum length of 255',
            silhouetteType: 'Silhouette type must be a string with a minimum length of 1 and a maximum length of 255',
            thirdParty: 'Third party must be a string with a minimum length of 1 and a maximum length of 255',
            fieldsOfPlay: 'Fields of play must be a string with a minimum length of 1 and a maximum length of 255',
            features: 'Features must be a string with a minimum length of 1 and a maximum length of 255',
            emeaFootballPlates: 'EMEA football plates must be a string with a minimum length of 1 and a maximum length of 255',
            emeaBusinessModel: 'EMEA business model must be a string with a minimum length of 1 and a maximum length of 255',
        },
    },
    required: [
        'id',
        'hindsightFileId',
        'materialCode',
        'division',
        'category',
        'family',
        'model',
        'description',
        'colorDescription',
        'style',
        'yearType',
        'age',
        'gender',
        'silhouette',
        'typeOfOrder',
        'segment',
        'consumer',
        'brand',
        'emeaDimension',
    ],
};
